<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
    <section class="py-0 my-0 bg-nuevo_dashboard-img">
      <div class="custom-container px-5">
        <!-- row 1 -->
        <div class="row mt-8" style="gap: 0.5rem">
          <div class="col-12 col-lg-12 p-0">
            <!-- info profile -->
            <cardInfoPaciente />
            <div class="row" style="width: 100% !important; margin: 0 auto; gap: 0.5rem">
              <div class="col-12 col-lg bg-dark rounded p-0" data-aos="fade-up-right" data-aos-duration="1000">
                <cardChartInfo title="Evaluaciones Departamentos" info="Promedios" />
              </div>
            </div>
          </div>
        </div>
        </div>
        <div id="Card" class="col-12 col-md-14">
          <CardTable :data="dataCards" />
        </div><br>
        <card>
        <div class="form1 ml-5 md-5" >
        <h3 class="card-title text-white mb-3"><b>Accidentes laborales</b></h3>
        <p>No reporta ningún accidente.</p>
        <form>
            <label for="fecha">Fecha:</label>
            <input type="date" id="fecha" required>      
            <label for="incapacidad">Incapacidad (días):</label>
            <input type="number" id="incapacidad" required>
            <label for="empresa">Empresa:</label>
            <input type="text" id="empresa" required>

            <br>
            <label for="cargo">Cargo en la empresa:</label>
            <input type="text" id="cargo" required>
            <label for="arl">ARL:</label>
            <input type="text" id="arl" required>
            <div class="col-4">
            <label for="descripcion" class="form-label">Diagnostico</label>  
            <b-form-textarea
              placeholder="Escriba una descripción..."
              rows="3"
              minlength="10"
              maxlength="550"
            ></b-form-textarea>
           </div>
            <div class="col-4">
            <label for="secuelas" class="form-label">Secuelas</label>  
            <b-form-textarea
              placeholder="Escriba una descripción..."
              rows="3"
              minlength="10"
              maxlength="550"
            ></b-form-textarea>
           </div>
            <div class="d-flex justify-content-end">
          <vs-button
            :loading="isCreating"
            type="submit"
            class="py-1"
            animation-type="vertical"
             
            size="large"
          >
          Guardar antecedente
            <template #animate> <i class="fas fa-plus"></i></template>           
          </vs-button>
         </div>
        </form>
    </div>
  </card>
    </section>
  </b-col>
  </b-row>
  </b-container>

  </template>

<script>
import moment_timezone from "moment-timezone";
// import modal from "../../../components/helexium/modalHome.vue";
// import HeaderStyle10 from "@/components/partials/backend/HeaderStyle/HeaderStyle10";
import CardTable from "../card/CardTableHis";
// import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import cardInfoPaciente from "../card/cardInfoPaciente.vue";
// import cardChartInfo from "./cardChartInfo.vue";

export default {
  components: {
    // HeaderStyle10,
    cardInfoPaciente,
    // cardChartInfo,
    CardTable,
    // BtnAddCardTable,
  },

  data() {
    return {
      usuario: {},
      dataCicloEvaluacion: {},
      autorizacion: {},
      dataCards: [
        {
          title: "Medicina Ocupacional",
          value: "Laboral",
          iconName: "briefcase",
          color: "rgba(46, 134, 193)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuLaboral' })
          },
        },
        {
          title: "Medicina Ocupacional",
          value: "Eventos",
          iconName: "calendar-days",
          color: "rgba(46, 134, 193)",
          fontFamily : "arial",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuEventos' })
          },

        },
        {
          title: "Medicina Ocupacional",
          value: "Familiar",
          iconName: "people-roof",
          color: "rgba(125, 60, 152)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuFamiliar' })
          },
        },
        {
          title: "Medicina Ocupacional",
          value: "Personal",
          iconName: "user-tie",
          color: "rgba(125, 60, 152)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuPersonal' })
          },
        },
        {
          title: "Medicina Ocupacional",
          value: "Habitos",
          iconName: "heart-circle-bolt",
          color: "rgba(192, 57, 43)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuHabitos' })
          },
        },
        {
          title: "Medicina Ocupacional",
          value: "Fisico",
          iconName: "dumbbell",
          color: "rgba(192, 57, 43)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuFisico' })
          },
        },
        {
          title: "Medicina Ocupacional",
          value: "Otros",
          iconName: "ellipsis",
          color: "rgba(113, 125, 126)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.$router.push({ name: 'MedOcuOtros' })
          },
        },
        ]
    };
 
  },
  async mounted() {
      this.selectedSeccion = this.secciones[0]
  },
  methods: {
  },
  filters: {
    formatDate(date) {
      return moment_timezone.tz(date, "America/Bogota").format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>
#Card{
  margin-top: 15px;
  margin-bottom: 15px;

}
</style>